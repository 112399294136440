<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <!-- <ion-buttons slot="start">
          <ion-menu-button color="secondary"></ion-menu-button>
        </ion-buttons> -->
        <img alt="logo" height="40" src="../../public/INESCTECLogo.svg" />

        <ion-title>Copernicus Climatic Information</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="padding" :fullscreen="true">
      <div
        style="
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <ion-card>
          <ion-card-header class="ion-text-center">
            <ion-card-subtitle
              >Copernicus Climatic Information</ion-card-subtitle
            >
            <br />
            <ion-card-title>
              <b><ion-icon :icon="pin"></ion-icon></b>

              <b> {{ cityName }} </b>
            </ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <ion-segment value="val" @ionChange="segmentChanged($event)">
              <ion-segment-button value="0">
                <ion-label>Temperature</ion-label>
              </ion-segment-button>
              <ion-segment-button value="1">
                <ion-label>Wind</ion-label>
              </ion-segment-button>
              <ion-segment-button value="2">
                <ion-label>Humidity</ion-label>
              </ion-segment-button>
              <ion-segment-button value="3">
                <ion-label>Precipitation</ion-label>
              </ion-segment-button>
            </ion-segment>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Start Date</ion-label>
                    <ion-datetime
                      value="start"
                      @ionChange="startChange($event)"
                      display-format="MM/DD/YYYY"
                      min="2020-01-01"
                      max="2020-12-31"
                    ></ion-datetime>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">End Date</ion-label>
                    <ion-datetime
                      value="end"
                      @ionChange="endChange($event)"
                      display-format="MM/DD/YYYY"
                      min="2020-01-01"
                      max="2020-12-31"

                    ></ion-datetime>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div id="graphContainer">
              <Plotly
                :data="plotData"
                :layout="layout"
                :display-mode-bar="false"
              ></Plotly>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  // IonButtons,
  IonContent,
  IonHeader,
  // IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  // IonSelect,
  // IonSelectOption,
  IonLabel,
  IonRow,
  IonGrid,
  IonCol,
  IonDatetime,
  IonSegment,
  IonSegmentButton,
  IonIcon,
} from "@ionic/vue";
import { defineComponent, toRefs } from "vue";
import { pin } from "ionicons/icons";
import * as Plotly from "plotly.js";
import { cityInfo } from "@/store.js";

export default defineComponent({
  name: "Tab3",
  components: {
    IonCard,
    IonCardContent,
    IonCardTitle,
    // IonButtons,
    IonContent,
    IonHeader,
    // IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    Plotly,
    IonItem,
    // IonSelect,
    // IonSelectOption,
    IonLabel,
    IonRow,
    IonGrid,
    IonCol,
    IonDatetime,
    IonSegment,
    IonSegmentButton,
    IonIcon,
  },
  setup() {
    const { title, id } = toRefs(cityInfo);

    const index = [
      "Temperature",
      "Wind",
      "Relative Humidity",
      "Total Precipitation",
    ];
    return {
      cityName: title,
      cityId: id,
      val: null,
      // value: null,
      start: null,
      end: null,
      index,
      pin,
    };
  },
  // http://localhost:8080/FROST-Server/v1.1/Things(1)/Datastreams?$expand=Observations($select=phenomenonTime,result;$filter=phenomenonTime%20ge%20%202021-01-01T00:00:00.000Z;$orderby=phenomenonTime%20asc)&$filter=name%20eq%20%27Wind%27
  methods: {
    getData: async function () {
      const url = `https://methusalix.inesctec.pt/sensorthings/FROST-Server/v1.1/Things(${
        this.cityId
      })/Datastreams?$expand=Observations($select=phenomenonTime,result;$filter=phenomenonTime ge ${
        this.start
      } and phenomenonTime le ${
        this.end
      };$orderby=phenomenonTime asc)&$filter=name eq '${this.index[this.val]}'
         `;

      const response = await fetch(url);

      const json = await response.json();

      let obs = [];

      obs = obs.concat(json.value[0].Observations);

      let followNextLink = json.value[0]["Observations@iot.nextLink"];
      console.log("json1: ");
      console.log(json);

      while (followNextLink != undefined) {
        const response = await fetch(followNextLink);
        const json = await response.json();
        obs = obs.concat(json.value);

        followNextLink = json["@iot.nextLink"];
        console.log("jsonwhile: ");
        console.log(json);
      }
      console.log(obs);
      const x = [];
      const y = [];

      console.log("len " + obs.length);
      for (let i = 0; i < obs.length; i++) {
        x.push(obs[i].phenomenonTime);
        y.push(obs[i].result);
        console.log(
          "x: " + obs[i].phenomenonTime + " " + "y: " + obs[i].result
        );
      }
      const trace1 = {
        x: x,
        y: y,
        type: "scatter",
      };
      Plotly.newPlot("graphContainer", [trace1]);
      // console.log(data);
    },
    // selectChange: function (event) {
    //   this.val = event.target.value;
    //   console.log("val: " + this.val);
    //   if (this.val != null && this.start != null && this.end != null) {
    //     this.getData();
    //   }
    // },
    startChange: function (event) {
      this.start = event.target.value;
      // this.start = this.start.toISOString();
      this.start = new Date(this.start).toISOString();
      console.log("start: " + this.start);

      if (this.val != null && this.start != null && this.end != null) {
        this.getData();
      }
    },
    endChange: function (event) {
      this.end = event.target.value;
      this.end = new Date(this.end).toISOString();

      console.log("end: " + this.end);

      if (this.val != null && this.start != null && this.end != null) {
        this.getData();
      }
    },
    segmentChanged: function (event) {
      console.log("Segment changed", event);
      this.val = event.target.value;
      console.log("val: " + this.val);
      if (this.val != null && this.start != null && this.end != null) {
        this.getData();
      }
    },
  },
});
</script>
<style>
#graphContainer {
  height: 350px;
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
</style>
